import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DATA } from '../../shared/data';
import { Missionary } from '../../shared/models/missionary';
import { Broadcast } from '../../shared/models/broadcast';

@Component({
  selector: 's9-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  data = DATA;

  missionaries$: BehaviorSubject<Missionary[]> = new BehaviorSubject<
    Missionary[]
  >(this.data.missionaries);
  broadcastLinks$: BehaviorSubject<Broadcast[]> = new BehaviorSubject<
    Broadcast[]
  >(this.data.broadcastLinks.filter((z) => z.active));
}
