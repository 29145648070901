import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SacramentListComponent } from './sacrament-list/sacrament-list.component';
import { BroadcastListComponent } from './broadcast-list/broadcast-list.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { MissionaryListComponent } from './missionary-list/missionary-list.component';

@NgModule({
  declarations: [
    HomeComponent,
    SacramentListComponent,
    BroadcastListComponent,
    AlertsComponent,
    ContactInfoComponent,
    MissionaryListComponent,
  ],
  imports: [CommonModule, HomeRoutingModule],
})
export class HomeModule {}
