<div class="card border-light">
  <div class="card-img-wrapper"></div>

  <div class="card-body">
    <section class="broadcast-section">
      <h1 class="h4">Ward Information</h1>
      <ul class="list-group list-group-flush">
        <li class="list-group-item ml-2 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="fa-3x">
              <i class="fas fa-user-tie"></i>
            </div>

            <div class="ml-4 w-100">
              <strong>Chapel/Building Scheduler</strong><br />
              <ul class="p-0 m-0">
                <li class="mt-2 d-flex justify-content-between">
                  <span>Kristy Busby</span>
                  <a href="tel:8013107476" target="_blank">
                    <i class="fas fa-sms">(801) 310 7476</i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="ml-4 w-100">
              <a
                class="btn btn-sm btn-info font-size-12 text-capitalize"
                href="https://www.facebook.com/groups/442332393472318"
              >
                <strong>Join our Ward Facebook Group</strong><br />
              </a>
            </div>
          </div>
        </li>
        <li class="list-group-item ml-2 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="fa-3x">
              <i class="fas fa-place-of-worship"></i>
            </div>

            <div class="ml-4 mt-2">
              <strong>Chapel Address</strong><br />
              <a href="https://goo.gl/maps/ibehTjMqU2ub8gas6" target="_blank">
                1178 S Pondside Dr, Saratoga Springs<br />
                UT 84045 <br />
                United States
              </a>
            </div>
          </div>
        </li>
      </ul>
    </section>

    <br />
    <section class="broadcast-section">
      <h1 class="h4" id="stake-info">Stake Information</h1>
      <ul class="list-group list-group-flush">
        <li class="list-group-item ml-2 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="fa-3x">
              <i class="fas fa-user-tie"></i>
            </div>

            <div class="ml-4 w-100">
              <strong>Temple Recommend or Endorsement Appointments</strong
              ><br />
              <ul class="p-0 m-0">
                <li class="mt-2 d-flex justify-content-between">
                  <span>Viliami Fotu:</span>
                  <a href="tel:8014718893" target="_blank">801-471-8893</a>
                </li>
                <li class="mt-2 d-flex justify-content-between">
                  <span>Tony Wolfgramm: </span>
                  <a href="tel:8012434881" target="_blank">801-243-4881</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="list-group-item ml-2 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="fa-3x">
              <i class="fas fa-user-tie"></i>
            </div>

            <div class="ml-4 w-100">
              <strong>Stake President Appointments</strong><br />
              <ul class="p-0 m-0">
                <li class="mt-2 d-flex justify-content-between">
                  <span>David Takapu:</span>
                  <a href="tel:6502916760" target="_blank"> 650-291-6760 </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="list-group-item ml-2 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="fa-3x">
              <i class="fas fa-place-of-worship"></i>
            </div>

            <div class="ml-4 mt-2">
              <strong>Stake Presidency Office</strong><br />
              <a href="https://goo.gl/maps/61YsgZNYcrQzVX7N9" target="_blank">
                680 N 350 W <br />
                American Fork UT 84003 <br />
                United States
              </a>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </div>
</div>
