import { Component, Input } from '@angular/core';
import { Broadcast } from '../../../shared/models/broadcast';

@Component({
  selector: 's9-broadcast-list',
  templateUrl: './broadcast-list.component.html',
  styleUrls: ['./broadcast-list.component.scss'],
})
export class BroadcastListComponent {
  @Input() broadcastLinks: Broadcast[];
}
