<section>
  <div
    class="alert alert-primary p-3 d-flex align-content-between"
    role="primary"
  >
    <div>
      <b>
        <h5>Welcome <br />to the <br />Saratoga Springs 9th Ward!</h5>
      </b>
    </div>
    <br />
    <div>
      Sacrament Meeting starts at 1:30pm every Sunday, except stake, general and
      semi-annual conferences.
      <!-- <b>We are now doing both sacrament and second hour in-person ONLY. </b> -->
      <br />We invite all to attend with us at the chapel in-person. <br /><b
        >For announcements/calendar events, please click on the MENU above </b
      >.
    </div>
  </div>
</section>

<div class="card border-light">
  <div class="card-img-wrapper"></div>
  <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fas fa-user-clock fa-3x mr-3"></i></div>
    <div>
      <h5 class="alert-heading">Bishopric Appointment Scheduler</h5>
      <p>
        If you want to schedule an appointment with any member of Bishopric,
        just click the link below.
      </p>

      <p class="text-right mb-0">
        <!-- <a
          class="btn btn-info btn-sm"
          href="https://docs.google.com/document/d/1G3mnJlq8E-Oulx9DJriYcuYt7uae02BHe48d11_yrgY/edit?usp=sharing"
          target="_blank"
          >Schedule an appointment</a
        > -->
        <a
          href="https://www.picktime.com/56473c2e-d1c0-46b8-a6cb-9743b8c0fcb7"
          class="ptbkbtn"
          style="float: none"
          ><img
            border="none"
            src="https://www.picktime.com/bookingPage/img/picktime-book-online.png"
            alt="Schedule an appointment with Saratoga Springs 9th Ward"
        /></a>
      </p>
      <!-- <br />
      <p>
        NOTE: If you need to schedule an appointment with the Stake Presidency,  <a href="../../contact-info.component.html#stake-info">click here</a> for contact information. 
      </p> -->
    </div>

    <!-- <div class="class="alert alert-warning d-flex" role="alert">
        <div><i class="fas fa-user-clock fa-3x mr-3"></i></div>
          <div>
            <h5 class="alert-heading">
              Stake Primary “Come Follow Me” Calendar Activity (Aug.1-31)
            </h5>
              <p>
                For more information and to get a copy of the calendar,
                <a
                  class="btn btn-warning btn-sm"
                  href="https://drive.google.com/file/d/1hhUgzzs8SHOlwJBgiyRqe_BOxVXLMgPE/view?usp=sharing"
                  target="_blank"
                >click here!</a>
                <br />
                Primary children are encouraged to see the Bishopric on Sundays throughout
                August and let them know they’ve participated in the challenge in exchange
                for a treat. :)
              </p>
          </div>
      </div>
   -->
    <!-- </div> -->
    <!-- </section>
</div> -->
    <!-- <p class="text-right mb-0">
        <a
          class="btn btn-warning btn-sm"
          href="http://mywebcast.churchofjesuschrist.org/oremutah2ndstake"
          target="_blank"
          >Click here to join</a>
      </p> -->
  </div>

  <!-- Tithing Settlement Sign-up  -->
  <!-- <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fas fa-user-clock fa-3x mr-3"></i></div>
    <div>
      <h5 class="alert-heading">Tithing Settlement Sign-up Sheet</h5>
      <p>
        To sign-up for the year-end tithing settlement with the Bishop, just
        click the link below.
      </p>
      <p class="text-right mb-0">
        <a
          class="btn btn-info btn-sm"
          href="https://calendly.com/ss9/tithing-settlements?back=1&month=2021-12"
          >Sign-up Now!</a
        >
      </p>
    </div>
  </div> -->
</div>
