import { Component, Input } from '@angular/core';
import { Missionary } from '../../../shared/models/missionary';

@Component({
  selector: 's9-missionary-list',
  templateUrl: './missionary-list.component.html',
  styleUrls: ['./missionary-list.component.scss'],
})
export class MissionaryListComponent {
  @Input() missionaries: Missionary[];
}
