<div class="row mb-4">
  <div class="col-12 mb-3">
    <s9-alerts></s9-alerts>
  </div>

  <div class="col-12 mb-3" id="sacrament-programs">
    <s9-sacrament-list></s9-sacrament-list>
  </div>

  <div class="col-12 mb-3" id="broadcast-links">
    <s9-broadcast-list
      [broadcastLinks]="broadcastLinks$ | async"
    ></s9-broadcast-list>
  </div>

  <div class="col-12 mb-3" id="missionaries">
    <s9-missionary-list [missionaries]="missionaries$ | async">
    </s9-missionary-list>
  </div>

  <div class="col-12 mb-3" id="information">
    <s9-contact-info></s9-contact-info>
  </div>
</div>
