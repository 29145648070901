<div class="card border-light">
  <div class="card-img-wrapper"></div>

  <!-- <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fas fa-user-clock fa-3x mr-3"></i></div>
    <div>
      <h5 class="alert-heading">Ward Missionary Meal Calendar</h5>
      <p>
        If you would like to sign up to provide meals for the full-time
        missionaries in our ward, just click the link below. Malo!
      </p>
      <p class="text-right mb-0">
        <a
          class="btn btn-info btn-sm"
          href="https://docs.google.com/spreadsheets/d/1CjnrN4l-y3wH-j1W-aCboK6GPeqK3-H0tN8BprnJxIE/edit?usp=sharing"
          target="_blank"
          >Sign Up</a
        >
      </p>
    </div>
  </div> -->

  <div class="card-body">
    <h1 class="h4">Serving Full Time Missionaries</h1>
    <ul class="list-group list-group-flush">
      <li
        *ngFor="let missionary of missionaries"
        class="list-group-item ml-2 pl-0 mr-0 pr-0"
      >
        <div class="d-flex justify-content-start">
          <img class="photo mr-3" [src]="missionary.photoUrl" alt="" />
          <div>
            <strong>{{ missionary.name }}</strong
            ><br />
            {{ missionary.mission }}<br />
            {{ missionary.startDate | date }} to {{ missionary.endDate | date }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
