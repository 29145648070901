<div class="card border-light">
  <div class="card-img-wrapper"></div>
  <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fas fa-user-friends fa-3x mr-3"></i></div>
    <div>
      <h3 class="alert-heading">Sacrament Program</h3>
      <!-- <p>
        <b>NOTE:</b> We are now doing both in-person and Zoom broadcast option
        for sacrament.
      </p> -->
      <!-- <p>
        <b>NOTE:</b> Sacrament meeting is now in-person ONLY. The option for
        Zoom broadcast of the sacarament is no longer offered. Any sacrament
        ordinance that needs to be administered at home will need the
        Bishopric's approval.
      </p> -->
      <p class="text-right w-100">
        <a [routerLink]="'/sacrament-program'" class="btn btn-info btn-sm"
          >View Program Here!</a
        >
      </p>
    </div>
  </div>
  <!-- <div class="card border-light">
    <div
      class="alert alert-warning p-3 d-flex align-content-between"
      role="warning"
    >
      <div><i class="fas fa-child fa-3x mr-3"></i></div>
      <div>
        <h5 class="alert-heading">
          Stake Primary “Come Follow Me” Calendar Activity (Aug.1-31)
        </h5>
        <p>
          For more information and to get a copy of the calendar,
          <a
            class="btn btn-warning btn-sm"
            href="https://drive.google.com/file/d/1hhUgzzs8SHOlwJBgiyRqe_BOxVXLMgPE/view?usp=sharing"
            target="_blank"
            >click here!</a
          >
          <br />
          Primary children are encouraged to see the Bishopric on Sundays
          throughout August and let them know they’ve participated in the
          challenge in exchange for a treat. :)
        </p>
      </div>
    </div>
  </div> -->

  <div class="card border-light">
    <!-- <div class="card-img-wrapper1"></div> -->

    <!-- <div
          class="alert alert-warning p-3 d-flex align-content-between"
          role="warning"
        > -->
    <!-- <div class="text-center">
              <img src="/website/apps/web/src/assets/images/eq-rs-activity.jpg" class="rounded" alt="click to enlarge">
            </div>


            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    background: url(/website/apps/web/src/assets/images/eq-rs-activity.jpg);" class="rounded" alt="flyer">
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
      </div>-->
  </div>

  <!-- <section>
      <h1 class="h4">Sacrament Program</h1>
      <ul class="list-group list-group-flush">
        <li class="list-group-item ml-0 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="text-center">
              <div class="fa-3x">
                <i class="fas fa-user-friends"></i>
              </div>
            </div>
            <div class="ml-4 mt-2 w-100">
              <h2 class="h6 font-weight-bold">In-Person</h2>
              <p>
                This program is meant for those attending the session in-person.
              </p>
              <div class="text-right w-100">
                <a
                  [routerLink]="'/sacrament/in-person'"
                  class="btn btn-info btn-sm"
                  >View Program</a
                >
              </div>
            </div>
          </div>
        </li>
         <li class="list-group-item ml-0 pl-0 mr-0 pr-0">
          <div class="d-flex justify-content-start">
            <div class="text-center">
              <div class="fa-3x">
                <i class="fas fa-tv"></i>
              </div>
            </div>
            <div class="ml-4 mt-2 w-100">
              <h2 class="h6 font-weight-bold">Virtual</h2>
              <p>
                This program is meant for those watching the broadcast from home
                via Zoom.
              </p>
              <div class="text-right w-100">
                <a
                  [routerLink]="'/sacrament/broadcast'"
                  class="btn btn-info btn-sm"
                  >View Program</a
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </div>-->
</div>
<!-- </div> -->
