<div class="card border-light">
  <!-- <div class="card-img-wrapper"></div> -->
  <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <section class="broadcast-section">
      <div class="d-flex align-items-start">
        <i class="fas fa-tv fa-3x mr-3"></i>
        <h3>Zoom Links</h3>
      </div>
      <p class="mt-3">
        <b
          >NOTE: We will no longer be offering Zoom for Sacrament Meeting and
          invite you to join us at our Chapel. 1178 South Pondside Drive,
          Saratoga Springs UT 84045</b
        >
      </p>

      <!-- <div class="alert alert-danger p-3">
                      <h5 class="alert-heading font-size-14 font-weight-bold">
                        July 4th will be the last day for having the sacrament Zoom broadcast
                        option. Starting next week, we will have both Sacrament and 2nd hour
                        in-person ONLY. <br /><br />
                        After July 4th, any sacrament ordinance that needs to be administered
                        at home will need Bishop's approval.<br />-->

      <!-- Training for Zoom classes 
                    <div class="alert alert-info p-3">
                      <div>
                        <h5 class="alert-heading font-size-16 font-weight-bold">
                          How to prepare for the 2nd-hour Sunday classes using Zoom?
                        </h5>
                        <p>
                          <a [routerLink]="'/trainings'" class="btn btn-info btn-sm">
                            Click here for the training</a
                          >
                        </p>
                      </div> 
                    </div> -->

      <div class="card border-light">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item ml-0 pl-0 mr-0 pr-4"
            *ngFor="
              let broadcastItem of broadcastLinks;
              let elementIndex = index
            "
          >
            <div class="d-flex justify-content-start">
              <!-- <div class="text-center">
                    <div class="fa-3x">
                      <i class="fas fa-tv"></i>
                    </div>
                  </div> -->
              <div class="ml-4 mt-0 w-100">
                <div class="d-flex justify-content-between align-items-center">
                  <h2 class="h6 font-weight-bold">{{ broadcastItem.label }}</h2>
                  <span class="text-right">
                    <a
                      data-toggle="collapse"
                      href="#b-details-{{ elementIndex }}"
                      role="button"
                      aria-expanded="false"
                      aria-controls="b-details"
                      class="btn btn-outline-info btn-sm mr-2"
                      >Details</a
                    >
                    <a
                      [href]="broadcastItem.link"
                      disabled
                      target="_blank"
                      [class.disabled]="
                        !(broadcastItem.link && broadcastItem.link.length)
                      "
                      class="btn btn-info btn-sm"
                      >Join Zoom</a
                    >
                  </span>
                </div>
                <div
                  class="details w-100 mt-3 mb-3 collapse"
                  id="b-details-{{ elementIndex }}"
                >
                  <ul class="list-group list-group-flush w-100">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <strong>Link:</strong>
                      <a [href]="broadcastItem.link" target="_blank">{{
                        broadcastItem.link
                      }}</a>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <strong>Passcode:</strong>
                      <span>{{ broadcastItem.passcode }}</span>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <strong>Meeting ID:</strong>
                      <span>{{ broadcastItem.meetingId }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <!-- <div class="alert alert-secondary p-3" role="secondary">
                      <div>
                        <h5 class="alert-heading font-size-16 font-weight-bold">
                          Sunday 2:00pm - 4:00pm Ward Gathering
                        </h5>
                        <p>
                          We will be having a gathering before changes take place
                          with our ward.
                        </p>
                        <p>
                          Please remember to SOCIAL DISTANCE, WEAR A MASK and
                          refrain from attending if you exhibit any symptoms or if you aren't
                          feeling well. We want everyone to gather safely!!!
                        </p>
                        <p>
                          Malo....
                        </p>
                        <p class="mb-0">
                          <a href="https://goo.gl/maps/4G4rB71U3SrLtrT79" target="_blank">
                            Karl Malone Center<br />
                            525 E 200 S, Lehi, UT 84043
                          </a>
                        </p>
                      </div>
                    </div>

                    <div class="alert alert-secondary p-3" role="secondary">
                      <div>
                        <h5 class="alert-heading font-size-16 font-weight-bold">
                          6:00pm - Stake - Special Stake Fireside
                        </h5>
                        <p>
                          Special Stake Fireside for Eagle Mountain 13th, Lehi 41st and American Fork 2nd via zoom
                        </p>
                        <p class="text-right mb-0">
                          <a href="http://mywebcast.churchofjesuschrist.org/oremutah2ndstake"
                            target="_blank" class="btn btn-secondary btn-sm">Join via Zoom</a>
                        </p>
                      </div>
                    </div> -->
      </div>
    </section>
  </div>

  <!-- <div class="card border-light">
    <div class="card-img-wrapper1"></div>
    <br />
  </div> -->
  <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fa fa-thumb-tack fa-3x mr-3"></i></div>
    <div>
      <h5 class="alert-heading">Sunday Lessons</h5>
      <p>
        For the list of Sunday Lessons for the Elders Quorum & Relief Society,
        <a
          href="https://docs.google.com/document/d/1PSO62ALIi8RzLW-zmYi9gYBESc7NubWQ/edit?usp=sharing&ouid=110817037625640156494&rtpof=true&sd=true"
          target="_blank"
          >click here!</a
        >
      </p>
    </div>
  </div>
  <br />
  <div class="alert alert-info p-3 d-flex align-content-between" role="info">
    <div><i class="fa fa-thumb-tack fa-3x mr-3"></i></div>
    <div>
      <h5 class="alert-heading">FSY Registration</h5>
      <p>
        Registration for the For the Strength of Youth conference will open
        Mar.10th. for more info and how to register,
        <a
          href="https://www.churchofjesuschrist.org/youth/childrenandyouth/fsy/about?lang=eng"
          target="_blank"
          >click here!</a
        >
      </p>
    </div>
    <br />
  </div>
  <!-- <div class="card border-light">
    <div class="card-img-wrapper2"></div>
    <br />
  </div> -->
  <!-- <div class="card border-light">
    <div class="card-img-wrapper2"></div>
    <br />
  </div> -->
  <div class="card border-light">
    <!-- <div class="card-img-wrapper"></div> -->
    <!-- <div class="alert alert-info p-3 d-flex align-content-between" role="info">
      <div><i class="fas fa-file-alt fa-3x mr-3"></i></div>
      <div>
        <h5 class="alert-heading">Need your Tax Summary Statement?</h5>
        <p>
          You can print your official Tax Summary Statement directly from
          ChurchofJesusChrist.org.
          <a
            href="https://www.churchofjesuschrist.org/church/news/quick-tip-printing-your-official-tax-summary-from-ldsorg?lang=eng"
            target="_blank"
            >Click Here!</a
          >
        </p>
      </div>
      <br />
    </div> -->

    <div class="card border-light">
      <div class="card-img-wrapper"></div>
      <div
        class="alert alert-info p-3 d-flex align-content-between"
        role="info"
      >
        <div><i class="fas fa-user-clock fa-3x mr-3"></i></div>
        <div>
          <h5 class="alert-heading">Planning to go to the Temple?</h5>
          <p>
            Click on the link below to schedule an appointment online. Please
            note, you will need to sign in with your LDS church account.
            <br />If you don't have an account yet,
            <a
              href="https://account.churchofjesuschrist.org/register"
              target="_blank"
              >Click Here!</a
            >
          </p>
          <p>
            If you need to renew your temple recommend, schedule an appointment
            with the Bishopric above or
            <a
              href="https://www.picktime.com/56473c2e-d1c0-46b8-a6cb-9743b8c0fcb7"
              class="ptbkbtn"
              style="float: none"
              >click here!</a
            >
          </p>
          <p class="text-right mb-0">
            <a
              class="btn btn-info btn-sm"
              href="https://www.churchofjesuschrist.org/temples/schedule/appointment?lang=eng"
              target="_blank"
              >Schedule Temple Appointment
            </a>
          </p>
          <br />
          <!-- <h5 class="alert-heading">Youth Temple Day</h5>
          <p>
            <strong>Apr. 13, 2022</strong> - If you have any questions or
            concerns, please text Sister Ema Kaka at 808-785-1519.
            <a
              class="btn btn-info btn-sm"
              href="https://docs.google.com/spreadsheets/d/1l5xFStlwc2OtmjfiBKhnYjlhG2jgYKtg5IgglWQrBZk/edit?fbclid=IwAR3aswHj82M2ia4IYEctx0eTh_OptW9Bg6aDq3dfpzTw4TchdSlxi2xgREY#gid=0"
              target="_blank"
              >Sign up here!
            </a>
          </p> -->
        </div>

        <br />
      </div>
      <div class="card border-light">
        <div class="card-img-wrapper3"></div>
        <div
          class="alert alert-info p-3 d-flex align-content-between"
          role="info"
        >
          <div><i class="fas fa-book-reader fa-3x mr-3"></i></div>
          <div>
            <h4 class="alert-heading">Church Magazines</h4>
            <p>
              <i
                >"The First Presidency encourages all members to read the Church
                magazines. The magazines can help members learn the gospel of
                Jesus Christ, study the teachings of living prophets, feel
                connected to the global Church family, face challenges with
                faith, and draw closer to God."</i
              >
            </p>
            <p>
              <b>(For printed copies)</b>
              <br /><a
                href="https://store.churchofjesuschrist.org/magazines-3074457345616678843-1/subscriptions/new-friend-subscription"
                target="_blank"
              >
                click here</a
              >, to subscribe to the <i><b>Friend</b></i
              >. <br /><a
                href="https://store.churchofjesuschrist.org/magazines-3074457345616678843-1/subscriptions/for-the-strength-of-youth-subscription"
                target="_blank"
                >click here</a
              >, to subscribe to <i><b>For the Strength of Youth</b></i
              >. <br /><a
                href="https://store.churchofjesuschrist.org/magazines-3074457345616678843-1/subscriptions/liahona-subscription-85059832-1"
                target="_blank"
                >click here</a
              >, to subscribe to the <i><b>Liahona</b></i
              >. <br />You can also subscribe by calling
              <a href="tel:18005375971" target="_blank">1-800-537-5971</a>.
            </p>
            <p>
              <b>(For online/digital copies)</b> Please download the
              <a
                href="https://www.churchofjesuschrist.org/pages/mobileapps/gospellibrary?lang=eng"
                target="_blank"
                ><b>Gospel Library</b></a
              >
              and
              <a
                href="https://lds365.com/2020/02/14/new-gospel-living-app-now-available-worldwide/"
                target="_blank"
                ><b>Gospel Living</b></a
              >
              apps. <br /><br /><a
                href="https://store.churchofjesuschrist.org/magazinesfaq"
                target="_blank"
                ><b>Frequently Asked Questions</b></a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
